@import "ui/styles/2-tools/tools.media-query.scss";
@import "ui/styles/2-tools/tools.typography-mixins.scss";
@import "ui/styles/2-tools/tools.aspect-ratio.scss";
@import "ui/styles/2-tools/tools.behavior.scss";

.PreviewCard {
	margin-bottom: var(--spacing--3xl);
	@include mq("md") {
		margin-bottom: var(--spacing--2xl);
	}

	@include mq("lg") {
		margin-bottom: var(--spacing--5xl);
	}
}

.PreviewCard_image {
	padding-bottom: var(--spacing--base);
	@include aspect-ratio(325, 280);
	overflow: hidden;
	@include mq("md") {
		padding-bottom: var(--spacing--2xl);

		&___small {
			// almost square
			@include aspect-ratio(392, 375);
		}

		&___medium {
			// landscape
			@include aspect-ratio(37, 25);
		}

		&___large {
			// portrait
			@include aspect-ratio(196, 275);
		}
	}
	picture {
		transform: scale(1.05);
		@include transition {
			transition: transform var(--trans-time--shorter);
		}
		.PreviewCard:hover & {
			transform: scale(1.01);
		}
	}
}

.PreviewCard_tags {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	padding: var(--spacing--base) 0;

	@include mq("sm") {
		flex-direction: row;
		justify-content: space-between;
	}

	> p:nth-child(2) {
		text-align: right;
	}
}

.PreviewCard_headline {
	padding-bottom: var(--spacing--xs);
	@include body--lg-med;
}

// Theme: Kogp
[data-theme='kogp'] {
	.PreviewCard_headline {
		font-weight: var(--font-weight--bold);
	}
}
