@import "ui/styles/2-tools/tools.media-query.scss";
@import "ui/styles/2-tools/tools.colors.scss";

.ThreeColumnSpot {
	@include coloredComponent;
	padding: var(--spacing--5xl) 0;

	@include mq("md") {
		padding: var(--spacing--10xl) 0 var(--spacing--3xl);
	}
}

.ThreeColumnSpot_content {
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;
	gap: var(--spacing--3xl);

	@include mq("md") {
		flex-direction: row;
	}
}

.ThreeColumnSpot_heading {
	margin-bottom: var(--spacing-3xl);
}

.ThreeColumnSpot_ctaLink {
	order: 1;

	@include mq("md") {
		order: 0;
	}
}

.ThreeColumnSpot_articles {
	flex-basis: 100%;
}
